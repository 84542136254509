<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <!-- Basic Settings -->

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5 max-w-6xl" v-if="isLoaded">
      <div class="flex justify-between items-center">
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-mobile-alt text-22px text-oPurple"></i>
          <span class="pl-3">Basic Settings</span>
        </div>
      </div>
      <div :key="`basic_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in basicSettings">
          <SmartAppInput
            :key="itemIndex"
            :rowId="`basic_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            :options="item.options"
            v-model="basic[`${item.variable}`]"
            @save="onSave(`${item.variable}`, $event)"
          />
        </template>
      </div>

      <!-- Personal ID Verification -->

      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="id-card-css-icon" />
          <span class="pl-3">Personal ID Verification</span>
        </div>
      </div>
      <div
        :key="`personalIDVerification_${counter}`"
        class=" mt-3 pt-5 border-t-2"
      >
        <SmartAppInput
          :key="`personalIDVerification_${counter + 0}`"
          :rowId="`personalIDVerification_${0}`"
          :type="personalIDVerificationSettings[0].type"
          :label="personalIDVerificationSettings[0].label"
          :description="personalIDVerificationSettings[0].description"
          :isEdit="personalIDVerificationSettings[0].isEdit"
          :options="personalIDVerificationSettings[0].options"
          v-model="personal[`${personalIDVerificationSettings[0].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[0].variable}`, $event)
          "
        />
        <SmartAppInput
          v-if="personal.doc_verification_required"
          :key="`personalIDVerification_${counter + 1}`"
          :rowId="`personalIDVerification_${1}`"
          :type="personalIDVerificationSettings[1].type"
          :label="personalIDVerificationSettings[1].label"
          :description="personalIDVerificationSettings[1].description"
          :isEdit="personalIDVerificationSettings[1].isEdit"
          :options="personalIDVerificationSettings[1].options"
          v-model="personal[`${personalIDVerificationSettings[1].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[1].variable}`, $event)
          "
        />
        <SmartAppInput
          v-if="
            personal.doc_verification_required && personal.driver_id_required
          "
          :key="`personalIDVerification_${counter + 2}`"
          :rowId="`personalIDVerification_${2}`"
          :type="personalIDVerificationSettings[2].type"
          :label="personalIDVerificationSettings[2].label"
          :description="personalIDVerificationSettings[2].description"
          :isEdit="personalIDVerificationSettings[2].isEdit"
          :options="personalIDVerificationSettings[2].options"
          v-model="personal[`${personalIDVerificationSettings[2].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[2].variable}`, $event)
          "
        />
        <SmartAppInput
          v-if="
            personal.doc_verification_required && personal.driver_id_required
          "
          :key="`personalIDVerification_${counter + 3}`"
          :rowId="`personalIDVerification_${3}`"
          :type="personalIDVerificationSettings[3].type"
          :label="personalIDVerificationSettings[3].label"
          :description="personalIDVerificationSettings[3].description"
          :isEdit="personalIDVerificationSettings[3].isEdit"
          :options="personalIDVerificationSettings[3].options"
          v-model="personal[`${personalIDVerificationSettings[3].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[3].variable}`, $event)
          "
        />
        <SmartAppInput
          v-if="
            personal.doc_verification_required && personal.driver_id_required
          "
          :key="`personalIDVerification_${counter + 4}`"
          :rowId="`personalIDVerification_${4}`"
          :type="personalIDVerificationSettings[4].type"
          :label="personalIDVerificationSettings[4].label"
          :description="personalIDVerificationSettings[4].description"
          :isEdit="personalIDVerificationSettings[4].isEdit"
          :options="personalIDVerificationSettings[4].options"
          v-model="personal[`${personalIDVerificationSettings[4].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[4].variable}`, $event)
          "
        />
        <SmartAppInput
          v-if="personal.doc_verification_required"
          :key="`personalIDVerification_${counter + 5}`"
          :rowId="`personalIDVerification_${5}`"
          :type="personalIDVerificationSettings[5].type"
          :label="personalIDVerificationSettings[5].label"
          :description="personalIDVerificationSettings[5].description"
          :isEdit="personalIDVerificationSettings[5].isEdit"
          :options="personalIDVerificationSettings[5].options"
          v-model="personal[`${personalIDVerificationSettings[5].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[5].variable}`, $event)
          "
        />
        <SmartAppInput
          :key="`personalIDVerification_${counter + 6}`"
          :rowId="`personalIDVerification_${6}`"
          :type="personalIDVerificationSettings[6].type"
          :label="personalIDVerificationSettings[6].label"
          :description="personalIDVerificationSettings[6].description"
          :isEdit="personalIDVerificationSettings[6].isEdit"
          :options="personalIDVerificationSettings[6].options"
          v-model="personal[`${personalIDVerificationSettings[6].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[6].variable}`, $event)
          "
        />
        <SmartAppInput
          :key="`personalIDVerification_${counter + 7}`"
          :rowId="`personalIDVerification_${7}`"
          :type="personalIDVerificationSettings[7].type"
          :label="personalIDVerificationSettings[7].label"
          :description="personalIDVerificationSettings[7].description"
          :isEdit="personalIDVerificationSettings[7].isEdit"
          :options="personalIDVerificationSettings[7].options"
          v-model="personal[`${personalIDVerificationSettings[7].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[7].variable}`, $event)
          "
        />
      </div>
      <!-- ID Re-verification -->
      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="id-card-css-icon" />
          <span class="pl-3">ID Re-verification</span>
        </div>
      </div>
      <div :key="`iDReVerification_${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartAppInput
          :key="`iDReVerification_${counter + 0}`"
          :rowId="`iDReVerification_${0}`"
          :type="iDReVerificationSettings[0].type"
          :label="iDReVerificationSettings[0].label"
          :description="iDReVerificationSettings[0].description"
          :placeholder="iDReVerificationSettings[0].placeholder"
          :isEdit="iDReVerificationSettings[0].isEdit"
          :options="iDReVerificationSettings[0].options"
          v-model="personalRe[`${iDReVerificationSettings[0].variable}`]"
          @save="onSave(`${iDReVerificationSettings[0].variable}`, $event)"
        />
        <SmartAppInput
          v-if="personalRe.id_re_verification_enabled"
          :key="`iDReVerification_${counter + 1}`"
          :rowId="`iDReVerification_${1}`"
          :type="iDReVerificationSettings[1].type"
          :label="iDReVerificationSettings[1].label"
          :description="iDReVerificationSettings[1].description"
          :placeholder="iDReVerificationSettings[1].placeholder"
          :isEdit="iDReVerificationSettings[1].isEdit"
          :options="iDReVerificationSettings[1].options"
          v-model="personalRe[`${iDReVerificationSettings[1].variable}`]"
          @save="onSave(`${iDReVerificationSettings[1].variable}`, $event)"
        />
        <SmartAppInput
          v-if="personalRe.id_re_verification_enabled"
          :key="`iDReVerification_${counter + 2}`"
          :rowId="`iDReVerification_${2}`"
          :type="iDReVerificationSettings[2].type"
          :label="iDReVerificationSettings[2].label"
          :description="iDReVerificationSettings[2].description"
          :placeholder="iDReVerificationSettings[2].placeholder"
          :isEdit="iDReVerificationSettings[2].isEdit"
          :options="iDReVerificationSettings[2].options"
          v-model="personalRe[`${iDReVerificationSettings[2].variable}`]"
          @save="onSave(`${iDReVerificationSettings[2].variable}`, $event)"
        />
      </div>

      <!-- other Verification -->
      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="id-card-css-icon" />
          <span class="pl-3">Other Verification</span>
        </div>
      </div>
      <div :key="`otherVerification_${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartAppInput
          :key="`otherVerification_${counter + 0}`"
          :rowId="`otherVerification_${0}`"
          :type="otherVerificationSettings[0].type"
          :label="otherVerificationSettings[0].label"
          :description="otherVerificationSettings[0].description"
          :placeholder="otherVerificationSettings[0].placeholder"
          :isEdit="otherVerificationSettings[0].isEdit"
          :options="otherVerificationSettings[0].options"
          v-model="other[`${otherVerificationSettings[0].variable}`]"
          @save="onSave(`${otherVerificationSettings[0].variable}`, $event)"
        />
        <SmartAppInput
          v-if="other.is_age_verification_required"
          :key="`otherVerification_${counter + 1}`"
          :rowId="`otherVerification_${1}`"
          :type="otherVerificationSettings[1].type"
          :label="otherVerificationSettings[1].label"
          :description="otherVerificationSettings[1].description"
          :placeholder="otherVerificationSettings[1].placeholder"
          :isEdit="otherVerificationSettings[1].isEdit"
          :options="otherVerificationSettings[1].options"
          v-model="other[`${otherVerificationSettings[1].variable}`]"
          @save="onSave(`${otherVerificationSettings[1].variable}`, $event)"
        />
      </div>

      <!-- security -->
      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="id-card-css-icon" />
          <span class="pl-3">Security</span>
        </div>
      </div>
      <div :key="`security_${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartAppInput
          :key="`security_${counter + 0}`"
          :rowId="`security_${0}`"
          :type="securitySettings[0].type"
          :label="securitySettings[0].label"
          :description="securitySettings[0].description"
          :placeholder="securitySettings[0].placeholder"
          :isEdit="securitySettings[0].isEdit"
          :options="securitySettings[0].options"
          v-model="security[`${securitySettings[0].variable}`]"
          @save="onSave(`${securitySettings[0].variable}`, $event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { BasicSettingConfig } from '@/config/SettingsConfig'
import { EventBus } from '@/utils'

export default {
  name: 'BasicSettings',
  components: {
    SmartAppInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      basic: {
        privacy_policy_url: '',
        terms_of_service_url: '',
        terms_and_conditions_url: '',
        about_url: '',
        help_url: '',
        faq_url: '',
        helpline: '',
        idle_vehicle_loc_update_mins: 10,
        low_battery_noti_percentage: 10,
      },
      personal: {
        driver_id_required: 0,
        personal_id_required: 0,
        doc_verification_required: 1,
        doc_verification_required_for_trip: 1,
        auto_verification: 0,
        driver_id_back_pic_required: false,
        driver_id_expiration_date_required: false,
        driver_id_issue_date_required: false,
      },
      other: {
        age_verification_minimum_age: 0,
        is_age_verification_required: false,
      },
      personalRe: {
        id_re_verification_enabled: false,
        id_re_verification_period_type: null,
        id_re_verification_period_value: 0,
      },
      security: {
        user_topup_daily_limit: 0,
      },
      inPlaceEditingState: {
        basic: false,
        personal: false,
        other: false,
        personalRe: false,
      },
      basicSettings: [
        {
          type: 'text',
          label: 'Privacy Policy',
          placeholder: `e.g. https://www.example.com`,
          description: 'Enter Privacy policy URL for your Org',
          variable: 'privacy_policy_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Terms of Service',
          placeholder: `e.g. https://www.example.com`,
          description: 'Enter Terms of Service URL for your Org.',
          variable: 'terms_of_service_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Terms & Conditions',
          placeholder: `e.g. https://www.example.com`,
          description: 'Enter Terms & Conditions URL for your Org.',
          variable: 'terms_and_conditions_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'About Link',
          placeholder: `e.g. https://www.example.com`,
          description: 'Enter URL which describes details of your Org.',
          variable: 'about_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'F.A.Q. Link',
          placeholder: `e.g. https://www.example.com`,
          description: 'Enter a Frequently Asked question URL',
          variable: 'faq_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Help Link',
          placeholder: `e.g. https://www.example.com`,
          description: 'Enter a Help link URL',
          variable: 'help_url',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Helpline',
          placeholder: `e.g +8801700000000`,
          description:
            'Enter your Official Customer Support number for Riders to reach you.',
          variable: 'helpline',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Idle Vehicle Location Update',
          placeholder: `e.g 3 m`,
          description: `Enter Interval time for the vehicle to update location to Server. If the value is higher it will impact the vehicle battery life of the IOT.`,
          options: [
            { value: 1, text: '1 m' },
            { value: 2, text: '2 m' },
            { value: 3, text: '3 m' },
            { value: 4, text: '4 m' },
            { value: 5, text: '5 m' },
            { value: 6, text: '6 m' },
            { value: 7, text: '7 m' },
            { value: 8, text: '8 m' },
            { value: 9, text: '9 m' },
            { value: 10, text: '10 m' },
          ],
          variable: 'idle_vehicle_loc_update_mins',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Low Battery Notification',
          placeholder: `e.g 10%`,
          description:
            'Enter Low battery % which you want System to Notify as Low battery.',
          options: [
            { value: '05.00', text: '05%' },
            { value: '10.00', text: '10%' },
            { value: '15.00', text: '15%' },
            { value: '20.00', text: '20%' },
            { value: '25.00', text: '25%' },
            { value: '30.00', text: '30%' },
          ],
          variable: 'low_battery_noti_percentage',
          isEdit: false,
        },
      ],
      personalIDVerificationSettings: [
        {
          type: 'richselect',
          label: 'Document Verification Required',
          description:
            'Select Yes if you want Rider to upload their Personal Identity document to submit after Sign up.',
          options: [
            { value: false, text: 'No' },
            { value: true, text: 'Yes' },
          ],
          variable: 'doc_verification_required',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Driver ID',
          description:
            'Select REQUIRED if you want Rider to upload their Driver ID document to submit after Sign up.',
          options: [
            { value: false, text: 'Not Required' },
            { value: true, text: 'Required' },
          ],
          variable: 'driver_id_required',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Driver ID Back Photo',
          description:
            'Select REQUIRED if you want Rider to upload their Driver ID back photo to submit after Sign up.',
          options: [
            { value: false, text: 'Not Required' },
            { value: true, text: 'Required' },
          ],
          variable: 'driver_id_back_pic_required',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Driver ID Expiration Date',
          description: 'Provide expire date mentioned in your driving ID.',
          options: [
            { value: false, text: 'Not Required' },
            { value: true, text: 'Required' },
          ],
          variable: 'driver_id_expiration_date_required',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Driver ID Issue Date',
          description:
            'Provide driving card issue date mentioned in your driving ID.',
          options: [
            { value: false, text: 'Not Required' },
            { value: true, text: 'Required' },
          ],
          variable: 'driver_id_issue_date_required',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Personal ID',
          description:
            'Select REQUIRED if you want Rider to upload their Personal document to submit after sign up',
          options: [
            { value: false, text: 'Not Required' },
            { value: true, text: 'Required' },
          ],
          variable: 'personal_id_required',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Trip Requires Document Verification',
          description:
            'Select Yes if you want Rider to upload their Driver ID document to submit documents and get verified before they take the trip.',
          options: [
            { value: false, text: 'No' },
            { value: true, text: 'Yes' },
          ],
          variable: 'doc_verification_required_for_trip',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Auto ID Verification',
          description:
            'Select Yes if you want Rider ID verification to be verified automatically through our third party API. $1/Verification.',
          options: [
            { value: false, text: 'Off' },
            { value: true, text: 'On' },
          ],
          variable: 'auto_verification',
          isEdit: false,
        },
      ],
      iDReVerificationSettings: [
        {
          type: 'richselect',
          label: 'Re-verification Enabled',
          description:
            'Select Yes if you want Personal ID to be reverified by the System.',
          options: [
            { value: false, text: 'No' },
            { value: true, text: 'Yes' },
          ],
          variable: 'id_re_verification_enabled',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Re-verification Notify Interval Type',
          description:
            'Select Re verification time Interval. E.g If you select 1y then System will ask Admin to reverify the document again.',
          options: [
            { value: 'MINUTE', text: 'Minute' },
            { value: 'HOUR', text: 'Hour' },
            { value: 'DAY', text: 'Day' },
            { value: 'MONTH', text: 'Month' },
            { value: 'YEAR', text: 'Year' },
          ],
          variable: 'id_re_verification_period_type',
          isEdit: false,
        },
        {
          type: 'number',
          label: 'Re-verification Notify Interval Value',
          description:
            'Enter Low battery % which you want System to Notify as Low battery.',
          placeholder: 'e.g 2',
          variable: 'id_re_verification_period_value',
          isEdit: false,
        },
      ],
      otherVerificationSettings: [
        {
          type: 'richselect',
          label: 'Age Verification',
          description:
            'Select YES if you want a rider to set a minimum age requirement for taking a trip.',
          options: [
            { value: false, text: 'No' },
            { value: true, text: 'Yes' },
          ],
          variable: 'is_age_verification_required',
          isEdit: false,
        },
        {
          type: 'number',
          label: 'Age Verification Minimum Age',
          description:
            'Provide age which will be checked by Sign up while taking ride. If Riders age below Minimum age then rider will not be allowed to take any trip',
          placeholder: 'e.g 14',
          variable: 'age_verification_minimum_age',
          isEdit: false,
        },
      ],
      securitySettings: [
        {
          type: 'number',
          label: 'Daily Successful Top-up Limit',
          description:
            'Please mention max number of successful Top-up a rider process per day',
          placeholder: 'e.g 2',
          variable: 'user_topup_daily_limit',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'basic') {
          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'personalIDVerification') {
          this.basicSettings = this.basicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'iDReVerification') {
          this.basicSettings = this.basicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'otherVerification') {
          this.basicSettings = this.basicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'security') {
          this.basicSettings = this.basicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
      },
    },
  },
  async created() {
    this.$http
      .get(BasicSettingConfig.api.index(this.orgId))
      .then((res) => {
        console.log('settings', res.data)
        let data = res.data

        // basic
        this.basic.privacy_policy_url = data.privacy_policy_url
        this.basic.terms_and_conditions_url = data.terms_and_conditions_url
        this.basic.terms_of_service_url = data.terms_of_service_url
        this.basic.faq_url = data.faq_url
        this.basic.about_url = data.about_url
        this.basic.help_url = data.help_url
        this.basic.helpline = data.helpline

        this.basic.idle_vehicle_loc_update_mins =
          data.idle_vehicle_loc_update_mins
        this.basic.low_battery_noti_percentage =
          data.low_battery_noti_percentage

        // personal
        this.personal.driver_id_required = data.driver_id_required
        this.personal.driver_id_back_pic_required =
          data.driver_id_back_pic_required
        this.personal.driver_id_expiration_date_required =
          data.driver_id_expiration_date_required
        this.personal.driver_id_issue_date_required =
          data.driver_id_issue_date_required
        this.personal.personal_id_required = data.personal_id_required
        this.personal.doc_verification_required = data.doc_verification_required
        this.personal.doc_verification_required_for_trip =
          data.doc_verification_required_for_trip
        this.personal.auto_verification = data.auto_verification
        this.personalRe.id_re_verification_enabled =
          data.id_re_verification_enabled
        this.personalRe.id_re_verification_period_type =
          data.id_re_verification_period_type
        this.personalRe.id_re_verification_period_value =
          data.id_re_verification_period_value
        this.other.is_age_verification_required =
          data.is_age_verification_required
        this.other.age_verification_minimum_age =
          data.age_verification_minimum_age
        this.security.user_topup_daily_limit = data.user_topup_daily_limit

        this.isLoaded = true
      })
      .catch((err) => {
        console.log('settignsErr', err)
      })
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'basic') {
        this.basicSettings = this.basicSettings.map((item, itemIndex) => {
          if (id === `basic_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'basic'
      }
      if (idPrefix === 'personalIDVerification') {
        this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
          (item, itemIndex) => {
            if (id === `personalIDVerification_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'personalIDVerification'
      }
      if (idPrefix === 'iDReVerification') {
        this.iDReVerificationSettings = this.iDReVerificationSettings.map(
          (item, itemIndex) => {
            if (id === `iDReVerification_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'iDReVerification'
      }
      if (idPrefix === 'otherVerification') {
        this.otherVerificationSettings = this.otherVerificationSettings.map(
          (item, itemIndex) => {
            if (id === `otherVerification_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'otherVerification'
      }

      if (idPrefix === 'security') {
        this.securitySettings = this.securitySettings.map((item, itemIndex) => {
          if (id === `security_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'security'
      }
    })
  },
  methods: {
    async onSave(key, val) {
      let toastTitle, toastText
      if (key === 'privacy_policy_url') {
        toastTitle = `Privacy Policy URL`
        toastText = `Privacy Policy URL is updated`
      }
      if (key === 'terms_of_service_url') {
        toastTitle = `Terms of Service URL`
        toastText = `Terms of Service URL is updated`
      }
      if (key === 'terms_and_conditions_url') {
        toastTitle = `Terms & Conditions URL`
        toastText = `Terms & Conditions URL is updated`
      }
      if (key === 'about_url') {
        toastTitle = `About Link`
        toastText = `About Link is updated`
      }
      if (key === 'faq_url') {
        toastTitle = `F.A.Q. Link`
        toastText = `F.A.Q. Link is updated`
      }
      if (key === 'help_url') {
        toastTitle = `Help Link`
        toastText = `Help Link is updated`
      }
      if (key === 'helpline') {
        toastTitle = `Helpline`
        toastText = `Helpline is updated`
      }
      if (key === 'idle_vehicle_loc_update_mins') {
        toastTitle = `Idle Vehicle Location Update Minute`
        toastText = `Idle Vehicle Location Update Time is updated`
      }
      if (key === 'low_battery_noti_percentage') {
        toastTitle = `Low Battery Notification Percentage`
        toastText = `Low Battery Notification Percentage is updated`
      }
      if (key === 'doc_verification_required') {
        toastTitle = `Document Verification`
        toastText = `Document Verification settings is updated`
      }
      if (key === 'driver_id_required') {
        toastTitle = `Driver ID `
        toastText = `Driver ID settings is updated`
      }
      if (key === 'driver_id_back_pic_required') {
        toastTitle = `Driver ID Back Photo`
        toastText = `Driver ID Back Photo settings is updated`
      }
      if (key === 'driver_id_expiration_date_required') {
        toastTitle = `Driver ID Expiration Date`
        toastText = `Driver ID Expiration Date settings is updated`
      }
      if (key === 'driver_id_issue_date_required') {
        toastTitle = `Driver ID Issue Date`
        toastText = `Driver ID Issue Date settings is updated`
      }
      if (key === 'personal_id_required') {
        toastTitle = `Personal ID`
        toastText = `Personal ID settings is updated`
      }
      if (key === 'doc_verification_required_for_trip') {
        toastTitle = `Trip Requires Document Verification`
        toastText = `Trip Requires Document Verification settings is updated`
      }
      if (key === 'auto_verification') {
        toastTitle = `Auto ID Verification`
        toastText = `Auto ID Verification settings is updated`
      }
      if (key === 'id_re_verification_enabled') {
        toastTitle = `Re-verification Enabled`
        toastText = `Re-verification Enabled settings is updated`
      }
      if (key === 'id_re_verification_period_type') {
        toastTitle = `Re-verification Notify Interval Type`
        toastText = `Re-verification Notify Interval Type settings is updated`
      }
      if (key === 'id_re_verification_period_value') {
        toastTitle = `Re-verification Notify Interval Value`
        toastText = `Re-verification Notify Interval Value is updated`
      }

      if (key === 'is_age_verification_required') {
        toastTitle = `Age Verification`
        toastText = `Age Verification is updated`
      }

      if (key === 'age_verification_minimum_age') {
        toastTitle = `Age Verification Minimum Age`
        toastText = `Age Verification Minimum Age is updated`
      }
      if (key === 'user_topup_daily_limit') {
        toastTitle = `Daily Successful Top-up Limit`
        toastText = `Daily Successful Top-up Limit is updated`
      }

      let data = new FormData()
      data.append(key, val)

      await this.$http
        .patch(BasicSettingConfig.api.update(this.orgId), data)
        .then((res) => {
          console.log(res)
          this.basicSettings = this.basicSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.id-card-css-icon {
  width: 13.5px;
  height: 22px;
  border-radius: 2px;
  background-color: #16cded;
}
</style>
